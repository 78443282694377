<template>
    <div>
        <div class="row page-title">
            <label>{{ pageTitle }}</label>
        </div>
        <div class="page-details row">
            <media-amend-overview class="col-lg-8" :ID="id" :Request="Request"></media-amend-overview>
            <div class="col-lg-4" v-if="IsDataLoaded">
                <div class="py-2 px-1">
                    <b-alert :show="!IsApproveOrRejectEnabled" class="alert-info-new position-fixed fixed-top m-0 rounded-0" variant="info" dismissible>
                        The Request is already approved or rejected.
                    </b-alert>
                    <p class="approve-reject-label px-3 pt-2 pb-1">Approve or Reject Amend Media Vendor Request</p>
                    <p class="approve-reject-content px-3 py-2">
                        When approved, this Amend Media Vendor will be sent to the Vendor Validation to approve.
                    </p>
                    <div class="text-right pb-3 pr-3" style="width: 100%">
                        <button v-if="IsApproveOrRejectEnabled" class="btn custom-button btn-primary" @click="Approve()">
                            Approve
                        </button>
                        <button v-else class="btn custom-button btn-primary" disabled="disabled"
                            title="The request is already approved or rejected.">
                            Approve
                        </button>
                    </div>
                </div>
                <div class="py-2 my-2 px-3">
                    <label class="">Rejection Comments</label>
                    <b-textarea class="rejection-comments-new m-custom" placeholder="Enter Rejection Comments" v-model="Comments"
                        v-bind:class="{
                            errorborder: !$v.Comments.required && $v.Comments.$dirty,
                            noterrorborder: $v.Comments.required,
                        }" rows="5" @blur="SetDirty()" @focus="ResetDirty()">
                    </b-textarea>
                    <div class="error-message-format required-field" v-if="!$v.Comments.required && $v.Comments.$dirty">
                        Required field
                    </div>
                    <div class="text-right" style="width: 100%; padding-top: 10px">
                        <button v-if="IsApproveOrRejectEnabled" class="btn custom-button btn-outline-primary"
                            @click="Reject()">
                            Reject
                        </button>
                        <button v-else class="btn custom-button btn-outline-primary" disabled="disabled"
                            title="The request is already approved or rejected.">
                            Reject
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from "@/store";
import * as status from "../../../utilities/VendorWorkflowStates";
import amendMediaVendorMixin from "../../../mixins/mediaamendworkflowvauthorization";
import { requiredIf } from "vuelidate/lib/validators";
export default {
    name: "ApproveLevel1",
    mixins: [amendMediaVendorMixin],
    validations: {
        Comments: {
            required: requiredIf(function () {
                return this.RejectComments == true;
            }),
        },
    },
    data() {
        return {
            Request: {},
            Comments: "",
            RejectComments: false,
        };
    },
    props: ["id"],
    computed: {
        RequestedCode() {
            return this.Request ? this.Request.ProductCode : "";
        },
        canApproveCurrentWorkflowRequest() {
            return this.$store.getters.canApprovePendingMrManagerVendorRequest;
        },
        IsApproveOrRejectEnabled() {
            return this.Request && this.Request.Status == status.MrApproval;
        },
        IsDataLoaded() {
            return this.Request && this.Request.VendorBusinessName ? true : false;
        },
    },
    methods: {
        SetDirty() {
            this.$v.Comments.$touch();
        },
        ResetDirty() {
            this.$v.Comments.$reset();
        },
        GetData() {
            return {
                ID: parseInt(this.id),
                Comments: this.Comments,
                ActiveTaskID: this.Request.ActiveTaskID,
                ProjectID: this.Request.OmniflowProjectID,
                ProcessID: this.Request.OmniflowProcessID,
            };
        },
        Reject() {
            this.RejectComments = true;
            this.$v.Comments.$touch();
            if (!this.$v.$invalid) {
                this.$store
                    .dispatch("vendormediaamend/RejectRequestLevel1", this.GetData())
                    .then(() => {
                        store.dispatch(
                            "progress/setSuccessMessage",
                            `Request ${this.pageTitle} has been rejected`
                        );
                        this.$router.push("/VendorRequests");
                    })
                    .catch((ex) => {
                        if (ex.response) {
                            this.SetErrorMessage(
                                "Error while rejecting request",
                                ex.response.data
                            );
                        } else {
                            this.SetErrorMessage("Error while rejecting request", ex);
                        }
                    });
            }
        },
        Approve() {
            this.RejectComments = false;
            this.$store
                .dispatch("vendormediaamend/ApproveRequestLevel1", this.GetData())
                .then(() => {
                    store.dispatch(
                        "progress/setSuccessMessage",
                        `The request ${this.pageTitle} has been approved`
                    );
                    this.$router.push("/VendorRequests");
                })
                .catch((ex) => {
                    if (ex.response) {
                        this.SetErrorMessage(
                            "Error while approving request",
                            ex.response.data
                        );
                    } else {
                        this.SetErrorMessage("Error while approving request", ex);
                    }
                });
        },
        SetErrorMessage(message, ex) {
            store.dispatch(
                "progress/setErrorMessage",
                `${message} ${this.RequestedCode}. The error is ${ex}`
            );
        },
    },
};
</script>

<style scoped>
.reject-section {
    padding-bottom: 10px;
}

@media screen and (min-width: 1200px) {
    .page-details {
        padding: 1rem 3rem 0px !important;
    }
}

.row.page-title {
    height: 55px;
    background-color: #eef3f5 !important;
}

.row.page-title>label {
    padding: 14px 0px 14px 55px !important;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.81px;
    color: #4d5161;
    text-transform: none !important;
}

.review-component>div:first-child {
    box-shadow: 0 1px 3px 0 rgb(9 30 66 / 12%) !important;
    border: solid 1px rgba(239, 242, 244, 0.2) !important;
    border-right: none;
    border-radius: 8px 0px 0px 8px;
    background-color: #ffffff;
}
</style>
